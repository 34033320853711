<template>
    <b-sidebar
        id="add-new-position-sidebar"
        :visible="isAddNewPositionSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-position-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>CARGA DE PUESTOS/CARGOS (EXCEL)</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

    
            <div>
                <a href="/plantillas/plantilla_importar_cargos.xlsx" download
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn btn-sm btn-success ml-2 mt-2"
                >
                    DESCARGAR PLANTILLA
                    <feather-icon
                        class="cursor-pointer"
                        icon="DownloadIcon"
                        size="16"
                        @click="hide"
                    />
                </a>
            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                
                    <validation-provider
                            #default="{ errors }"
                            name="rubro"
                            rules="required"
                        >
                            <b-form-group
                                label="Rubro"
                                label-for="business_area"
                            >
                                <v-select
                                    id="business_area"
                                    placeholder="Seleccione"
                                    v-model="userData.business_area"
                                    :options="optionsBusinessAreas"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>
                    
                <!--     <validation-provider
                            #default="{ errors }"
                            name="tarifario"
                            rules="required"
                        >
                            <b-form-group
                                label="Tarifario"
                                label-for="tariff"
                            >
                                <v-select
                                    id="tariff"
                                    placeholder="Seleccione"
                                    v-model="userData.type"
                                    :options="optionsTariffs"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider> -->
                
                    <!-- Names -->
                    <validation-provider
                        #default="{ errors }"
                        name="archivo"
                        rules="required">
                        <!-- Avatar -->
                        <b-form-group
                            label="Archivo"
                            label-for="excel-file"
                        >
                            <b-form-file
                                id="excel-file"
                                v-model="userData.file"
                                :state="errors.length > 0 ? false:null"
                                @change="onFileChange"
                                accept=".xls,.xlsx"
                                placeholder="Elija un archivo o suéltelo aquí ..."
                                drop-placeholder="Suelta el archivo aquí ...."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                     <!--    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            IMPORTAR
                        </b-button> -->
						
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled = "isLoading"
                        >
                            <b-spinner small v-if="isLoading"/>
                            {{ isLoading ? 'Cargando...' : 'IMPORTAR' }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            CANCELAR
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BSidebar, BForm, BFormGroup, BFormFile, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref, reactive } from '@vue/composition-api';
    import { required } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import vSelect from 'vue-select';
    import axios from 'axios';

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormFile,
            BFormInvalidFeedback,
            BButton,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
            vSelect,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewPositionSidebarActive',
            event: 'update:is-add-new-position-sidebar-active',
        },
        props: {
            isAddNewPositionSidebarActive: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                required,
            }
        },
        setup(props, { emit }) {

            // Use toast
            const toast = useToast();
            const business_area_selected = ref(null);
			const isLoading = ref(false);
            const isSubmitDisabled =  ref(true);
            const blankUserData = {
                business_area: null,
               /*  type: null, */
                file: null,
                stateFile: null
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
            };
            
            const optionsBusinessAreas = reactive([]);
       /*      const optionsTariffs = ['GRANDE', 'MEDIANA', 'PEQUEÑA']; */
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/business-area/select`)
			.then(response => {
				if(response){
					response.data.map( item => {
						optionsBusinessAreas.push({
							label : item.name, 
							value : item.id
						});
					});
				}
			});

            const onSubmit = () => {
                isLoading.value = true;

                let formData = new FormData();
                formData.append("file", userData.value.file);
                formData.append("business_area_id", userData.value.business_area.value);
                /* formData.append("type", userData.value.type);        */         
                
                axios
                    .post(`${process.env.VUE_APP_HOST}/api/dashboard/positions/import`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data'}
                    })
                    .then( (response) => {
						isLoading.value = false;
                        emit('refetch-data');

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });

                        emit('update:is-add-new-position-sidebar-active', false);
                    })
                    .catch( () => {
						isLoading.value = false;
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ha ocurrido un error al importar el archivo.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        });
                    });
            };

            const onFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    userData.value.file = file;
                    userData.value.stateFile = Boolean(userData.value.file);
                } else {
                    userData.value.file = null;
                    userData.value.stateFile = null;
                }
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onFileChange,
                onSubmit,
                
                business_area_selected,
                optionsBusinessAreas,
             /*    optionsTariffs, */
                
                refFormObserver,
                getValidationState,
                resetForm,

				isLoading,
                isSubmitDisabled
            }
        },
    }

</script>