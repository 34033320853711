<template>
    <b-sidebar
        id="provider-import-file-sidebar"
        :visible="isProviderImportFileSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-provider-import-file-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>CARGA MASIVA DE PROVEEDORES (EXCEL)</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>
            
            <div>
                <a  href="/plantillas/plantilla_importar_proveedores.xlsx"                     
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn btn-sm btn-success ml-2 mt-2"
                    download
                >
                    DESCARGAR PLANTILLA
                    <feather-icon
                        class="cursor-pointer"
                        icon="DownloadIcon"
                        size="16"
                        @click="hide"
                    />
                </a>
            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- Excel File -->
                    <validation-provider
                        name="archivo"
                        rules="required">
                        
                        <b-form-group
                            label="Archivo"
                            label-for="file"
                        >
                            <b-form-file
                                id="file"
                                v-model="userData.file"
                                :state="userData.stateFile"
                                @change="onFileChange"
                                accept=".xls,.xlsx"
                                placeholder="Elija un archivo o suéltelo aquí ..."
                                drop-placeholder="Suelta el archivo aquí ...."
                            />
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled="userData.stateFile ? false:true"
                        >
                            IMPORTAR
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            CANCELAR
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BSidebar, BForm, BFormGroup, BFormFile, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref } from '@vue/composition-api';
    import { required } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import axios from 'axios';

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormFile,
            BFormInvalidFeedback,
            BButton,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isProviderImportFileSidebarActive',
            event: 'update:is-provider-import-file-sidebar-active',
        },
        props: {
            isProviderImportFileSidebarActive: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                //PublicUrl: process.env.VUE_APP_URL,
                required
            }
        },
        setup(props, { emit }) {
            
            // Use toast
            const toast = useToast();

            const blankUserData = {
                file: null,
                stateFile: null
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
            };
            
            let fileExist = false;
            if(userData.value.stateFile) fileExist = true;
            
            const event = () => {
                if(!fileExist){
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe seleccionar algun archivo para importar.',
                            icon: 'CheckIcon',
                            variant: 'warning',
                        },
                    });   
                }
            }
            
            const onSubmit = () => {
                
                    let formData = new FormData();
                    formData.append("file", userData.value.file);
                    formData.append("auth", JSON.parse(localStorage.getItem('userData')).id );
                    
                    axios
                        .post(`${process.env.VUE_APP_HOST}/api/dashboard/provider/import`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data'}
                        })
                        .then( (response) => {
                            
                            emit('refetch-data');

                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            });

                            emit('update:is-provider-import-file-sidebar-active', false);
                        })
                        .catch( (err) => {
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Ha ocurrido un error al importar el archivo.',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            });
                        });
            };

            const onFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    userData.value.file = file;
                    userData.value.stateFile = Boolean(userData.value.file);
                } else {
                    userData.value.file = null;
                    userData.value.stateFile = null;
                }
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onFileChange,
                onSubmit,
                
                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }

</script>