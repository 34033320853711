<template>

    <div>
        
        <b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				
				<b-col sm="12" md="4">
					<label>Rubro(s)</label>
					<v-select 
                        multiple
					    v-model="filterBusinessArea"
					    :options="optionsBusinessAreas" />
				</b-col>
				<b-col sm="12" md="4">
					<label>Estado(s) del último formulario</label>
					<v-select 
                        multiple
					    v-model="filterState"
					    :options="optionsStates" />
				</b-col>
                <b-col sm="12" md="4">
                    <label>Fecha de inicio</label>
                    <flat-pickr
                        id="start_date_cycle"
                        v-model="filterStartDate"
                        class="form-control"
                        :config="config"    
                        placeholder="DD-MM-YYYY"                        
                    />
                </b-col>
                <b-col cols="12">
                    <label>Buscador</label>
                    <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Proveedor, RUC, Correo electrónico ..."
                    />
                </b-col>
                
                <b-col cols="12" class="mt-1">
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="selectAllRows"
                    >
                        Seleccionar Todos
                    </b-button>
                    
                    <b-button
                        class="ml-md-1 ml-0 mt-1 mt-md-0 mx-auto"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="clearSelected"
                    >
                        Limpiar Selección
                    </b-button>
                </b-col>                            
			</b-row>
		</b-card>

        <provider-list-add-new
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            @refetch-data="refetchData"
        />
        
        <provider-import-file
            :is-provider-import-file-sidebar-active.sync="isProviderImportFileSidebarActive"
            @refetch-data="refetchData"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="3"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>
                                  
                    <b-col
                        cols="12"
                        md="9"
                    >
                        <b-row class="text-center" align-h="around">
                            <b-col v-if="[1, 2].includes(user_id)" class="mt-1 mt-md0">
                                <b-button                                    
                                    @click="isAddNewPositionSidebarActive = true"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="primary"
                                >
                                    <span class="text-nowrap">Cargar Puestos</span>    
                                </b-button>
                            </b-col>
                            <b-col  v-if="[1, 2].includes(user_id)" class="mt-1 mt-md0">
                                <b-button                                   
                                    variant="success"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    @click="isProviderImportFileSidebarActive = true"
                                >
                                    <span class="text-nowrap">Carga masiva</span>                                
                                </b-button>    
                            </b-col>
                            <b-col class="mt-1 mt-md0">
                                <b-button
                                    variant="primary"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    @click="isAddNewUserSidebarActive = true"
                                >
                                    <span class="text-nowrap">Agregar Proveedor</span>                                
                                </b-button>
                            </b-col>
                            <b-col class="mt-1 mt-md0">
                                <b-button
                                    variant="primary"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    @click="modalAsignReviser"
                                >
                                    <span class="text-nowrap">Asignar Revisor</span>                                
                                </b-button>    
                            </b-col>                        
                        </b-row>                        
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refProviderListTable"
                class="position-relative"
                :style="totalUsers < 3 && totalUsers > 0 ? 'height: 250px' : 'height: inherit'"
                :items="fetchProviders"
                responsive
                :fields="tableColumns"
                primary-key="id2"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc"
                selectable
                :select-mode="selectMode"
                v-model="selected"
                @row-selected="onRowSelected"
            >

                <!-- Column: Provider -->
                <template #cell(access.names)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                size="32"
                                :src="avatarSrc(data.item.access.photo)"
                                :text="avatarText(data.item.access.names)"
                                variant="light-success"
                                :to="{ name: 'providers-edit', params: { id: data.item.id } }"
                            />
                        </template>
                        <b-link
                            :to="{ name: 'providers-edit', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap">
                            {{ data.item.access.names }}
                        </b-link>
                    </b-media>
                </template>
                
                <template #cell(business_area.name)="data">
                    <b-col>
                        {{ data.item.business_area.name }}
                    </b-col>
                </template>

                <template #cell(contract_numbers)="data">
                    <b-col style="width: 100px">
                        {{ data.item.contract_numbers ? data.item.contract_numbers : '---' }}
                    </b-col>                    
                </template>
                
                <template #cell(start_date_cycle)="data">
                    <b-col style="width: 150px">
                        {{ moment(data.item.start_date_cycle).format('DD-MM-YYYY') }}
                    </b-col>                    
                </template>
                
                <template #cell(forms_count)="data">
                    {{ data.item.forms[0] ? data.item.forms[0].forms_count : 0 }}                 
                </template>
                
                <template #cell(last_form)="data">
                    <template v-if="data.item.forms.length > 0">         
                        {{ lastFormFormat(data.item.forms[0].start_date) }}	
                    </template>
                    <template v-else>
                        ---
                    </template>
                </template>
                                
                <template #cell(forms.state.name)="data">
                    <template v-if="data.item.forms.length > 0">         
                        <b-badge 
                            :variant="`light-${data.item.forms[0].state.color}`"
                            >
                            {{ data.item.forms[0].state.name }}
                        </b-badge>	
                    </template>
                    <template v-else>
                        ---
                    </template>
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'providers-forms', params: { id: data.item.id } }">
                            <feather-icon icon="ClipboardIcon" />
                            <span class="align-middle ml-50">Formularios</span>
                        </b-dropdown-item>
                        
                        <b-dropdown-item :to="{ name: 'providers-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteUser(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
        
        <positions-import-file
            :is-add-new-position-sidebar-active.sync="isAddNewPositionSidebarActive"
        />       
        
        <b-modal
            ref="refAsignReviser"
            size="md"
            ok-title="ACEPTAR"
            cancel-title="CANCELAR"
            cancel-variant="outline-secondary"
            centered
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            title="ASIGNAR REVISOR AL ÚLTIMO FORMULARIO"
            no-close-on-backdrop
            hide-footer
        >
            <template #default="{ hide }">
                <validation-observer 
                    #default="{ handleSubmit }"
                    ref="refAsignReviserForm"
                >
                    <b-form
                        @submit.prevent="handleSubmit(updateAsignReviser)"
                    >                    
                        <validation-provider
                            #default="{ errors }"
                            name="Revisor"
                            rules="required"
                        >
                            <b-form-group
                                label="Revisor"
                                label-for="reviserSelect"
                            >
                                <v-select
                                    id="reviserSelect"
                                    placeholder="Seleccione"
                                    :options="optionsRevisers"
                                    v-model="reviserSelected"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                        <hr>
                        <div class="d-flex justify-content-end">                        
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                class="mr-2"
                                type="button"
                                @click="hide"
                            >
                                CANCELAR
                            </b-button>
                            
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                type="submit"
                            >
                                ACEPTAR
                            </b-button>
                        </div>                        
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BFormDatepicker } from 'bootstrap-vue';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import vSelect from 'vue-select';
    import store from '@/store';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { onUnmounted, ref, reactive, watch } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import useProvidersList from './useProvidersList';
    import providerStoreModule from '../providerStoreModule';
    import ProviderListAddNew from './ProviderListAddNew';
    import ProviderImportFile from './ProviderImportFile';
    import PositionsImportFile from './PositionsImportFile.vue';
    import flatPickr from 'vue-flatpickr-component'
    import Ripple from 'vue-ripple-directive';
    import Vue from 'vue';
    import moment from 'moment'
    import 'animate.css';
    import axios from 'axios'
    
    Vue.use(VueSweetalert2);

    export default {
        components: {
            ProviderListAddNew,
            ProviderImportFile,
            PositionsImportFile,
            
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BFormDatepicker,
            vSelect,
            flatPickr,
            
            ValidationProvider,
            ValidationObserver
        },
        directives: {
            Ripple
        },
        data() {                                   
            return {
                required,
                moment,
                config: {
                    enableTime: false, 
                    dateFormat: 'd-m-Y',
                    locale: Spanish
                },
                user_id: JSON.parse(localStorage.getItem('userData')).id,
                selectMode: 'multi',
                selected: [],
                
                optionsRevisers: [],
                reviserSelected: null
            }
        },
        methods: {
            avatarSrc (img){
                if (img){
                    if (img.includes('http')){
                        return img;
                    } else {
                        return `${process.env.VUE_APP_FILES}/modules/profiles/${img}`;
                    }
                } else {
                    return null;
                }
            },
            lastFormFormat (date){
                let month = moment(date).format('M')
                let year = moment(date).format('YYYY')
                let monthName = '';
  
                switch (month) {
                    case '1':
                        monthName = 'ENERO';
                        break;

                    case '2':
                        monthName = 'FEBRERO';
                        break;

                    case '3':
                        monthName = 'MARZO';
                        break;

                    case '4':
                        monthName = 'ABRIL';
                        break;

                    case '5':
                        monthName = 'MAYO';
                        break;

                    case '6':
                        monthName = 'JUNIO';
                        break;

                    case '7':
                        monthName = 'JULIO';
                        break;

                    case '8':
                        monthName = 'AGOSTO';
                        break;

                    case '9':
                        monthName = 'SEPTIEMBRE';
                        break;

                    case '10':
                        monthName = 'OCTUBRE';
                        break;

                    case '11':
                        monthName = 'NOVIEMBRE';
                        break;

                    default:
                        monthName = 'DICIEMBRE';
                }

                return `${monthName}, ${year}`
            },
            getRevisers () {
                this.optionsRevisers = []
                axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/revisers/select`)
                .then(response => {                    
                    response.data.map( item => {
                        this.optionsRevisers.push({
                            label : item.access.fullName, 
                            value : item.id
                        });
                    });
                });  
            },
            onRowSelected(items) {
                this.selected = items;
            },
            selectAllRows() {
                this.$refs.refProviderListTable.selectAllRows();
            },
            clearSelected() {
                this.$refs.refProviderListTable.clearSelected();
                this.selected = []
            },
            modalAsignReviser () {
                const existsRowsSelected = this.$refs.refProviderListTable.selectedRows.some( row => row === true)                
                    
                if (!existsRowsSelected) {
                    this.notification('Aviso', 'Debe seleccionar al menos un proveedor', 'warning')
                    return;
                }
                
                this.reviserSelected = null;
                this.getRevisers();
                this.$refs.refAsignReviser.show();
            },
            async updateAsignReviser () {
                
                this.$refs.refAsignReviserForm.validate().then( async success => {
                    if (success) {
                        try {
                            const provider_ids = this.selected.map( item => item.id) || []
                            const data = {
                                auth: this.user_id,
                                provider_ids,
                                reviser_id: this.reviserSelected.value || null
                            }
                            const res = await axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/revisers/asign`, { data })
                            if (res && res.data) {
                                this.$refs.refProviderListTable.refresh();
                                this.$refs.refAsignReviser.hide();
                                this.notification('Confirmación', res.data.message, 'success');
                            }              
                        } catch (error) {
                            this.notification('Error', error.response.data.message, 'error');
                        } 
                    }
                })
            },
            notification (title, text, icon) {
                this.$swal({
                    title,
                    text,
                    icon,
                    showConfirmButton: false,
                    timer: 3000,
                    buttonsStyling: false,
                });
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const USER_APP_STORE_MODULE_NAME = 'app-provider';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, providerStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });
                        
            const isAddNewUserSidebarActive = ref(false);
            const isAddNewPositionSidebarActive = ref(false);
            const isProviderImportFileSidebarActive = ref(false);
            
            const optionsBusinessAreas = reactive([]);
            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/business-area/select`)
            .then(response => {
                response.data.map( item => {
					optionsBusinessAreas.push({
						label : item.name, 
						value : item.id
					});
				});
            });
            
            const optionsStates = reactive([]);
            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/state/select`)
            .then(response => {
                 response.data.map( item => {
					optionsStates.push({
						label : item.name, 
						value : item.id
					});
				});
            });
            
            const {
                fetchProviders,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refProviderListTable,
                refetchData,
                
                filterBusinessArea,
                filterState,
                filterStartDate,
            } = useProvidersList();
            
            const deleteUser = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar al proveedor?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-provider/deleteProvider', { id, auth: JSON.parse(localStorage.getItem('userData')).id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el proveedor',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {

                // Sidebar
                isAddNewUserSidebarActive,            
                isAddNewPositionSidebarActive,
                isProviderImportFileSidebarActive,    

                fetchProviders,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refProviderListTable,
                refetchData,
                
                // Filter
                avatarText,
                deleteUser,
                optionsBusinessAreas,
                optionsStates,
                filterBusinessArea,
                filterState,
                filterStartDate,
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }    
    
    .b-table-selectable{
        .feather{
            font-size: 1.3rem;
        }
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>